<script lang="ts">
  import { api, apiCurrentUser } from '$lib/api'
  import { onMount } from 'svelte'
  import AccentLightColor from '$lib/components/AccentLightColor.svelte'
  import { analyticsFlush, analyticsInitialize, analyticsTrack } from '$lib/analytics'
  import BackButton from '$lib/components/BackButton.svelte'
  import TgBackgroundColor from '$lib/components/TgBackgroundColor.svelte'
  import { page } from '$app/stores'

  onMount(() => {
    const unload = () => {
      analyticsTrack('WebApp Closed')
      analyticsFlush()
    }

    window.addEventListener('beforeunload', unload)

    return () => {
      window.removeEventListener('beforeunload', unload)
    }
  })

  let initialized = false

  $: {
    if ($apiCurrentUser) {
      if (!initialized) {
        analyticsInitialize($apiCurrentUser, {
          channel:
            Telegram.WebApp?.initDataUnsafe?.start_param ??
            $page.url.searchParams.get('tgWebAppStartParam') ??
            undefined,
        })
        initialized = true
        analyticsTrack('WebApp Opened')
      }
    }
  }
</script>

<svelte:head>
  <script src="https://sdk.panl.so/panl.js" defer data-app-id="twixer"></script>
</svelte:head>

<BackButton />
<TgBackgroundColor />
<AccentLightColor />

<div class="h-full overflow-hidden">
  {#if $api.state === 'connected' && $apiCurrentUser}
    <slot />
  {:else if $api.state === 'error'}
    <div class="flex flex-col gap-4 p-2 py-6">
      <div class="text-center text-5xl">😭</div>
      <div class="font-mono">{$api.error}</div>
    </div>
  {/if}
</div>
