<script lang="ts">
  import { browser } from '$app/environment'
  import { appState } from '$lib/app-service'
  import { captureException } from '@sentry/sveltekit'
  import { onMount } from 'svelte'

  let prevColor = '#000000'
  onMount(() => {
    prevColor = Telegram.WebApp.backgroundColor
  })

  $: if (browser) {
    try {
      if ($appState === 'home' || $appState === 'account' || $appState === 'referrals') {
        Telegram.WebApp.setHeaderColor(Telegram.WebApp.themeParams.button_color ?? '#ffffff')
      } else {
        Telegram.WebApp.setHeaderColor(prevColor)
      }
    } catch (e) {
      captureException(e)
      console.error(e)
    }
  }
</script>
